import React, { FC } from "react";
import { graphql } from "gatsby";
import { PartnerPage } from "../page-components/PartnerPage";

const Partner: FC<{ data: any }> = ({ data }) => {
  return (
    <PartnerPage
      partner={data.partnerPagesDataJson}
      image={data[data.partnerPagesDataJson.featuredImage]}
    />
  );
};

export default Partner;
export const query = graphql`
  {
    partnerPagesDataJson {
      header {
        headlineText
        subscribeText
        tryAppText
      }
      description
      featuredImage
      headline
      appDetails {
        title
        firstSection {
          title
          icon
          description
        }
        secondSection {
          title
          icon
          description
        }
        thirdSection {
          title
          icon
          description
        }
      }
      alias
      id
      link
      title
      qa {
        question
        answer
      }
    }
    highRideCycleFeatured: file(
      relativePath: { eq: "partners/high-ride-cycle/featured-image.jpg" }
    ) {
      childImageSharp {
        fixed(width: 2000) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
