import styled from "styled-components";

const HeaderText = styled.h1`
  color: "#000";
  font-family: "Muli";
`;
export const TitleText = styled(HeaderText)`
  font-size: 35px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: center;
`;

export const ContactText = styled.h1`
  color: "#000";
  font-family: "Muli";
  font-weight: 400;
  font-size: 35px;
  margin: 0;
`;
export const ContactTextLink = styled.a`
  color: "#000";
  font-family: "Muli";
  font-weight: 400;
  font-size: 35px;
  color: #fcb333;
  margin: 0;
`;

const ParagraphText = styled.p`
  color: "#000";
  font-family: "Muli";
`;

export const NormalText = styled(ParagraphText)`
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  margin: 0;
`;
