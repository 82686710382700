import React, { FC } from "react";
import styled from "styled-components";
import { Partner } from "../../types";
import { Header } from "./Header";
const HEADER_HIGHT = 100;

const MainContainer = styled.div`
  padding: ${HEADER_HIGHT}px 0 0 0;
`;

interface TitleSectionProps {
  partner: Partner;
}
export const PartnerLayout: FC<TitleSectionProps> = ({ children, partner }) => {
  return (
    <>
      <Header partner={partner} />
      <MainContainer>{children}</MainContainer>
    </>
  );
};
