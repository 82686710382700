const STYLE_CONSTANTS = {
    small: 576,
    medium: 768,
    large: 992,
    extraLarge: 1200,
    huge: 1400
}



export const maxExtraSmallQuery = `@media only screen and (max-width: ${STYLE_CONSTANTS.small}px)`
export const maxMediumQuery = `@media only screen and (max-width: ${STYLE_CONSTANTS.medium}px)`
export const maxLargeQuery = `@media only screen and (max-width: ${STYLE_CONSTANTS.large}px)`
export const smallQuery = `@media only screen and (min-width: ${STYLE_CONSTANTS.small}px)`
export const mediumQuery = `@media only screen and (min-width: ${STYLE_CONSTANTS.medium}px)`
export const largeQuery = `@media only screen and (min-width: ${STYLE_CONSTANTS.large}px)`
export const extraLargeQuery = `@media only screen and (min-width: ${STYLE_CONSTANTS.extraLarge}px)`
export const hugeQuery = `@media only screen and (min-width: ${STYLE_CONSTANTS.huge}px)`