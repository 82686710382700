import React, { FC } from "react";
import styled from "styled-components";
import { mediumQuery } from "../../utils";
const TitleText = styled.h1`
  font-size: 2rem;
  ${mediumQuery} {
    font-size: 2.5rem;
  }
`;
const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  text-align: center;
  padding: 20px 0;
  ${mediumQuery} {
    padding: 82px 0;
  }
`;

interface TitleSectionProps {
  title: string;
}
export const TitleSection: FC<TitleSectionProps> = ({ title }) => {
  return (
    <TitleContainer>
      <TitleText>{title}</TitleText>
    </TitleContainer>
  );
};
