import React, { FC } from "react";
import { Partner } from "../../types";
import { TitleSection } from "./TitleSection";
import { ImageSection } from "./ImageSection";
import { AppDetailsSection } from "./AppDetailsSection";
import { FaqSection } from "./FaqSection";
import { ContactLinkSection } from "./ContactLinkSection";
import { PartnerLayout } from "./PartnerLayout";
import "./../../components/layout.css";

type Image = {
  childImageSharp: {
    fixed?: {
      base64: string;
      height: number;
      src: string;
      srcSet: string;
      width: number;
    };
  };
};

type PartnerPageProps = {
  partner: Partner;
  image: Image;
};

export const PartnerPage: FC<PartnerPageProps> = ({ partner, image }) => {
  return (
    <PartnerLayout partner={partner}>
      <TitleSection title={partner.title} />
      <ImageSection src={image.childImageSharp.fixed?.src} partner={partner} />
      <AppDetailsSection partner={partner} />
      <FaqSection partner={partner} />
      <ContactLinkSection />
    </PartnerLayout>
  );
};
