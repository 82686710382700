import React, { FC } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { Partner } from "../../types";
import { PrimaryButton } from "../../common/components";
import { SIZE } from "../../common/constants";
import logo from "../../images/home/logo.jpg";
import { TitleText, NormalText } from "./elements";
import { maxMediumQuery, mediumQuery } from "../../utils";
const HEADER_HIGHT = 100;
const StrydeLogo = styled.img`
  width: 133px;
  height: 27px;
  margin: 0;
`;

const StrydeLogoLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  gap: 15px;
  height: 48px;
  text-decoration: none;
  :hover {
    text-decoration: none;
  }
`;

const HeaderContainer = styled.div`
  position: fixed;
  background-color: #fff;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  height: ${HEADER_HIGHT}px;
  align-items: center;
  justify-content: center;
  ${maxMediumQuery} {
    max-width: 100%;
    padding: 0 20px;
    height: ${HEADER_HIGHT-20}px;
  }
`;

const HeaderInnerContainer = styled.div`
  display: flex;
  flex: 1;
  max-width: ${SIZE.MAX_WIDTH};
  justify-content: space-between;
  align-items: flex-end;
  ${mediumQuery} {
    width: 100%;
  }
`;

const TryAppContainer = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 10px;
  ${maxMediumQuery} {
    flex-direction: column;
    align-items: flex-end;
    gap: 0;
  }
`;

const HeaderMainText = styled(TitleText)`
  font-family: "Muli";
  font-size: 31px;
  font-weight: 700;
  line-height: 39px;
  letter-spacing: 0.1em;
  color: #000;
  margin: 0;
  ${maxMediumQuery} {
    display: none;
  }
`;

const TinyText = styled(NormalText)`
  font-size: 14px;
  color: #000;
  ${maxMediumQuery} {
    display: none;
  }
`;

const MobileView = styled.div``;

interface HeaderProps {
  partner: Partner;
}
export const Header: FC<HeaderProps> = ({ partner }) => {
  return (
    <HeaderContainer>
      <HeaderInnerContainer>
        <StrydeLogoLink to="/">
          <StrydeLogo src={logo} alt="Stryde Logo" />
          <HeaderMainText>{partner.header.headlineText}</HeaderMainText>
        </StrydeLogoLink>
        <TryAppContainer>
          <TinyText>{partner.header.subscribeText}</TinyText>
          <PrimaryButton to={partner.link} width={100}>
            {partner.header.tryAppText}
          </PrimaryButton>
        </TryAppContainer>
      </HeaderInnerContainer>
    </HeaderContainer>
  );
};
