import React, { FC } from "react";
import styled from "styled-components";
import { Partner } from "../../types";
import { PrimaryButton } from "../../common/components";
import { mediumQuery } from "../../utils";

const FeaturedImageContainer = styled.div`
  position: relative;
`;
const FeaturedImage = styled.img`
  width: 100%;
  object-fit: cover;
  height: 500px;
  ${mediumQuery}{
    height: none;
  }
`;
const ContentContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  ${mediumQuery}{
    padding: 0 100px;
  }
`;
const HeaderText = styled.h2`
  font-family: "Muli";
  font-weight: 700;
  font-size: 1.8rem;
  color: white;
  text-align: center;
  text-transform: uppercase;
  ${mediumQuery}{
    font-size: 1.95rem;
  }
`;

const DescriptionText = styled.p`
  font-stretch: normal;
  font-style: normal;
  font-family: "Muli";
  letter-spacing: normal;
  text-align: center;
  color: white;
  font-weight: 400;
  font-size: 30px;
  line-height: 40px;
  margin: 0;
  display: none;
  ${mediumQuery}{
    display: block;
  }
`;

const HorizontalSpace = styled.span`
  height: 24px;
`;

interface ImageSectionProps {
  src?: string;
  partner: Partner;
}

export const ImageSection: FC<ImageSectionProps> = ({ src, partner }) => {
  return (
    <FeaturedImageContainer>
      <FeaturedImage src={src} alt="Featured image" />
      <ContentContainer>
        <HeaderText>{partner.headline}</HeaderText>
        {Array.isArray(partner.description) &&
          partner.description.map(descriptionParagraph => (
            <DescriptionText>{descriptionParagraph}</DescriptionText>
          ))}
        {!Array.isArray(partner.description) && (
          <DescriptionText>{partner.description}</DescriptionText>
        )}
        <HorizontalSpace />
        <PrimaryButton to={partner.link} width={220}>
          subscribe today
        </PrimaryButton>
      </ContentContainer>
    </FeaturedImageContainer>
  );
};
