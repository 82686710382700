import React, { FC, useState } from "react";
import styled, { css } from "styled-components";
import { Partner } from "../../types";
import { TitleText, NormalText } from "./elements";
import { AddIcon, DashIcon } from "../../images/icons";
import { mediumQuery } from "../../utils";

const FAQ_PADDING = 32;

const FaqContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f2f2f2;
`;

const FaqTitle = styled(TitleText)`
  margin: 60px 0;
`;

type QuestionContainerProps = {
  isLastElement?: boolean;
  isSelected?: boolean;
  isUnderSelected?: boolean;
};
const QuestionContainer = styled.div<QuestionContainerProps>`
  display: flex;
  flex-direction: column;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  min-height: 50px;
  border-bottom: ${({ isLastElement }) =>
    isLastElement ? "1px solid rgba(0, 0, 0, 0.1);" : "none"};
  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: #fff;
      padding: 0 ${FAQ_PADDING}px 30px ${FAQ_PADDING}px;
      margin: 0 -${FAQ_PADDING}px;
      border: none;
      min-height: 200px;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
    `};
  ${({ isUnderSelected }) =>
    isUnderSelected &&
    css`
      border-top: none;
    `};
`;

const HeaderContainer = styled.div`
  display: flex;
  padding: 30px 0;
  align-items: flex-start;
  justify-content: space-between;
`;

const AnswerContainer = styled.div`
  display: flex;
`;

const QuestionsContainer = styled.div`
  width: 100%;
  padding: 0 ${FAQ_PADDING}px 20px ${FAQ_PADDING}px;
  max-width: 1000px;
  ${mediumQuery} {
    width: 60%;
    padding: 0 0 20px 0;
  }
`;

const Question = styled(TitleText)`
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  text-align: left;
  color: #333333;
`;

type FaqSectionProps = {
  partner: Partner;
};
export const FaqSection: FC<FaqSectionProps> = ({ partner }) => {
  const [selectedQuestion, setSelectedQuestion] = useState<undefined | number>(
    undefined
  );
  const isQuestionSelected = (index: number) =>
    selectedQuestion !== undefined && selectedQuestion === index;
  const isUnderSelected = (index: number) =>
    selectedQuestion !== undefined && selectedQuestion === index - 1;

  const selectQuestion = (index: number) => {
    if (selectedQuestion === index) {
      setSelectedQuestion(undefined);
    } else {
      setSelectedQuestion(index);
    }
  };
  return (
    <FaqContainer>
      <FaqTitle>FAQ</FaqTitle>
      <QuestionsContainer>
        {partner.qa.map((question, index) => {
          return (
            <QuestionContainer
              isLastElement={partner.qa.length === index + 1}
              isSelected={isQuestionSelected(index)}
              isUnderSelected={isUnderSelected(index)}
              onClick={() => selectQuestion(index)}
            >
              <HeaderContainer>
                <Question>{question.question}</Question>
                {isQuestionSelected(index) && <DashIcon />}
                {!isQuestionSelected(index) && <AddIcon />}
              </HeaderContainer>
              {isQuestionSelected(index) && (
                <AnswerContainer>
                  <NormalText>{question.answer}</NormalText>
                </AnswerContainer>
              )}
            </QuestionContainer>
          );
        })}
      </QuestionsContainer>
    </FaqContainer>
  );
};
