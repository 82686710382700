import React, { FC } from "react";
import styled from "styled-components";
import { Partner } from "../../types";
import { ContactText, ContactTextLink } from "./elements";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f2f2f2;
  padding: 40px 0;
`;

type ContactLinkSectionProps = {};

export const ContactLinkSection: FC<ContactLinkSectionProps> = ({}) => {
  return (
    <MainContainer>
      <ContactText>Still have questions?</ContactText>
      <ContactTextLink href="/">Send us a message.</ContactTextLink>
    </MainContainer>
  );
};
