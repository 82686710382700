import React, { FC } from "react";
import styled from "styled-components";
import { Partner } from "../../types";
import { SIZE } from "../../common/constants";
import {
  PlayBoxLogo,
  MedalLogo,
  MusicLogo,
} from "../the-bike/elements/iconElements";
import { TitleText } from "./elements";
import {maxMediumQuery} from '../../utils';

const DetailsSectionTitle = styled.h2`
  color: "black";
  font-family: "Muli";
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 18px;
`;
const DetailsSectionDetails = styled.p`
  color: "black";
  font-family: "Muli";
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: center;
  margin-top: 18px;
`;
const AppDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px 20px;
  max-width: ${SIZE.MAX_WIDTH};
`;

const OuterContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  margin-top: 40px;
  ${maxMediumQuery} {
    flex-direction: column;
  }
`;

const DetailsSectionContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

type AppDetailsSectionProps = {
  partner: Partner;
};
export const AppDetailsSection: FC<AppDetailsSectionProps> = ({ partner }) => {
  return (
    <OuterContainer>
      <AppDetailsContainer>
        <TitleText>{partner.appDetails.title}</TitleText>
        <SectionContainer>
          <DetailsSectionContainer>
            <PlayBoxLogo />
            <DetailsSectionTitle>
              {partner.appDetails.firstSection.title}
            </DetailsSectionTitle>
            <DetailsSectionDetails>
              {partner.appDetails.firstSection.description}
            </DetailsSectionDetails>
          </DetailsSectionContainer>
          <DetailsSectionContainer>
            <MedalLogo />
            <DetailsSectionTitle>
              {partner.appDetails.secondSection.title}
            </DetailsSectionTitle>
            <DetailsSectionDetails>
              {partner.appDetails.secondSection.description}
            </DetailsSectionDetails>
          </DetailsSectionContainer>
          <DetailsSectionContainer>
            <MusicLogo />
            <DetailsSectionTitle>
              {partner.appDetails.thirdSection.title}
            </DetailsSectionTitle>
            <DetailsSectionDetails>
              {partner.appDetails.thirdSection.description}
            </DetailsSectionDetails>
          </DetailsSectionContainer>
        </SectionContainer>
      </AppDetailsContainer>
    </OuterContainer>
  );
};
