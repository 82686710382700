import React from "react";
import styled from "styled-components";
import addIconImage from "./add.svg";
import dashIconImage from "./dash.svg";

const DashImg = styled.img`
  height: 4px;
  width: 24px;
  margin: 12px 0;
`;
const AddImg = styled.img`
  height: 24px;
  width: 24px;
  /* margin: auto 0; */
`;

export const AddIcon = () => {
  return <AddImg src={addIconImage} />;
};

export const DashIcon = () => {
  return <DashImg src={dashIconImage} />;
};
